import React, { useState, useEffect, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, Grid } from '@material-ui/core';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import Alert from '../../../components/common/Alert';
import EmptyCard from '../../../components/common/Cards/emptyCard';
import EnhancedTable from '../../../components/common/Table';
import empty from '../../../assets/images/empty.png';
import ProjectApi from '../project-api';
import Menu, { MenuContainer } from '../../../components/common/Menu';
import SessionAPI from '../../SessionContainer/session-api';
import { Input } from '../../../components/forms';
import Button from '../../../components/common/Button';
import AnnouncementModal from '../../AnnouncementContainer/AnnouncementInfoContainer/reschedule-announcement';
import { SITES } from '../../../utils/constants';

import InvitationModal from '../../../components/common/Modal/InvitationModal';

const SessionsTable = ({ project }) => {
  const history = useHistory();
  const [sessions, setSessions] = useState([]);
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [isMenuOpen, setIsMenuOpen] = useState({ open: false, id: '' });
  const [isOpen, setIsOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [idSession, setIdSession] = useState(0);
  const [message, setMessage] = useState('');
  const [isReassignModalOpen, setIsReassignModalOpen] = useState(false);
  const [reassignSessionId, setReassignSessionId] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);
  const [isInvitationModalOpen, setIsInvitationModalOpen] = useState(false);
  const [typeMessage, setTypeMessage] = useState('info');

  const onClick = (id) => {
    setIsMenuOpen((prev) => {
      return { id, open: !prev.open };
    });
  };

  useEffect(() => {
    ProjectApi.fetchSessionsByProject(project.announcementId)
      .then(({ data: { sessions } }) => {
        setSessions(sessions);
      })
      .catch((err) => setError(err.data.message))
      .finally(() => setIsLoading(false));
  }, [isLoading]);

  const handleCancelSession = (values) => {
    setIsSubmitting(true);
    SessionAPI.cancelSession(idSession, values)
      .then((response) => {
        setIsSubmitting(false);
        setMessage(response.data.message);
        setIsOpen(false);
        setIsLoading(true);
      })
      .catch((e) => {
        setIsOpen(false);
        setIsSubmitting(false);
        setMessage(e.data.message);
      })
      .finally(() => setIsLoading(false));
  };

  const itemMenuRender = {
    Cancelada: 'disabled',
    Abandonada: 'disabled',
    Activa: 'disabled',
    Finalizada: 'disabled',
    'No usada': 'disabled',
    'Sin Mail': 'warning',
    Creada: 'warning',
    'No Asignada': 'warning',
    Reagendada: 'disabled',
  };

  const url =
    window.location.origin === SITES.PROD.admin
      ? SITES.PROD.platform
      : SITES.DEV.platform;


  const copyTextToClipboard = (text) => {
    navigator.clipboard.writeText(text);
    setMessage('Link copiado al portapapeles');
    setTypeMessage('success');
  };

  const columns = useMemo(
    () => [
      {
        Header: 'Mentor',
        accessor: 'mentor.name',
      },
      {
        Header: 'Mentee',
        accessor: 'mentee.name',
      },
      { Header: 'Estado', accessor: 'status' },
      { Header: 'Fecha de la reunión', accessor: 'date' },
      { Header: 'Inicio', accessor: 'start' },
      { Header: 'Término', accessor: 'finish' },
      {
        Header: 'Acción',
        accessor: ({ id, status, space }) => {
          return (
            <MenuContainer onClick={() => onClick(id)}>
              {isMenuOpen.id === id && (
                <Menu
                  open={isMenuOpen.open}
                  items={[
                    {
                      text: 'Ver detalle',
                      action: () =>
                        history.push('/reuniones/detalle', {
                          idSession: id,
                        }),
                    },
                    {
                      text: 'Reagendar',
                      action: () => {
                        setSelectedSession(
                          sessions.find((session) => session.id === id)
                        );
                        setIsReassignModalOpen(true);
                        setReassignSessionId(id);
                      },
                    },
                    {
                      text: 'Link de reunión',
                      action: () => {
                          // if space is an array with length > 0, then define a code
                          const code = space.length > 0 ? space[0].code : '';
                          // url is defined from google meets if code exists and from url mentorias if not
                          const sessionUrl = code ? `https://meet.google.com/${code}` :  `${url}/mentorias/informacion?id=${id}`;
                        copyTextToClipboard(sessionUrl);
                      },
                    },
                    {
                      text: 'Agregar invitado',
                      action: () => {
                        setIdSession(id);
                        setIsInvitationModalOpen(true);
                      },
                    },
                    {
                      text: 'Cancelar',
                      action: () => {
                        if (itemMenuRender[status] !== 'disabled') {
                          setIsOpen(true);
                          setIdSession(id);
                        }
                      },
                      status: itemMenuRender[status],
                    },
                  ]}
                />
              )}
            </MenuContainer>
          );
        },
      },
    ],
    [isMenuOpen]
  );

  return (
    <>
    {message && <Alert type={typeMessage}>{message}</Alert>}
      {isOpen && (
        <Modal
          className="custom_modal"
          open={isOpen}
          onClose={() => setIsOpen(false)}
        >
          <div className="cancel-session">
            <h3>¿Estás seguro que deseas cancelar esta reunión?</h3>
            <Formik
              initialValues={{
                reason:
                  'Lo siento, no podré asistir a nuestra reunión. Gracias de todas formas',
              }}
              validationSchema={Yup.object({
                reason: Yup.string().required('Ingrese un motivo'),
              })}
              onSubmit={(values) => {
                handleCancelSession(values);
              }}
            >
              <Form>
                <div className="cancel__content">
                  <Input
                    label="Motivo"
                    placeholder="Motivo"
                    name="reason"
                    type="textarea"
                    max={500}
                    showCounter
                    isRequired={true}
                  />
                </div>
                <div className="info__button">
                  <div className="save-area">
                    <Grid container justify="space-around">
                      <Grid item xs={5}>
                        <Button
                          widthFull
                          secondary
                          onClick={() => {
                            setIsOpen(false);
                          }}
                        >
                          Cancelar
                        </Button>
                      </Grid>
                      <Grid item xs={5}>
                        <Button widthFull type="submit" loading={isSubmitting}>
                          Aceptar
                        </Button>
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Form>
            </Formik>
          </div>
        </Modal>
      )}
      <InvitationModal
        open={isInvitationModalOpen}
        onClose={() => setIsInvitationModalOpen(false)}
        sessionId={idSession}
      />
      {isReassignModalOpen && (
        <AnnouncementModal
          isOpen={isReassignModalOpen}
          onClose={() => setIsReassignModalOpen(false)}
          sessionData={{ ...selectedSession, clientId: project.clientId }}
        />
      )}

      {error && <Alert type="error">{error}</Alert>}
      {isLoading || sessions.length ? (
        <EnhancedTable
          data={sessions}
          columns={columns}
          isLoadingData={isLoading}
          tableName="Reuniones"
        />
      ) : (
        <div className="empty-tab">
          <EmptyCard
            message="Actualmente no existen reuniones para esta convocatoria."
            img={empty}
          />
        </div>
      )}
    </>
  );
};

export default SessionsTable;
